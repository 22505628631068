import Workshop from '@models/Workshop';

class WorkshopFactory {
    public create(node): Workshop {
        const { frontmatter, body, fields } = node;

        return new Workshop({
            index: frontmatter.index,
            image: frontmatter.image,
            date: new Date(frontmatter.date),
            length: frontmatter.length,
            title: frontmatter.title,
            description: frontmatter.description,
            body: body,
            path: fields.path,
            tags: frontmatter.tags,
            learn: frontmatter.learn,
            prerequisites: frontmatter.prerequisites,
        });
    }

    public batchCreate(nodes): Workshop[] {
        return nodes.map(({ node }) => this.create(node));
    }
}

export default new WorkshopFactory();
