import uuidv4 from 'uuid/v4';

export interface ContentInterface {
    index: number;
    date: Date;
    length: number;
    title: string;
    description: string;
    body: string;
    path: string;
    parentId?: string;
    [property: string]: any;
}

abstract class Content implements ContentInterface {
    public readonly id: string = uuidv4();
    public readonly index: number;
    public readonly date: Date;
    public readonly length: number;
    public readonly title: string;
    public readonly description: string;
    public readonly body: string;
    public readonly path: string;
    public readonly tags?: string[];
    public readonly learn?: string[];
    public readonly prerequisites?: string[];
    public readonly parentId?: string;

    public constructor({
        index,
        date,
        length,
        title,
        description,
        body,
        path,
        tags,
        learn,
        prerequisites,
        parentId,
    }: ContentInterface) {
        this.index = index;
        this.date = date;
        this.length = length;
        this.title = title;
        this.description = description;
        this.body = body;
        this.path = path;
        this.tags = tags;
        this.learn = learn;
        this.prerequisites = prerequisites;
        this.parentId = parentId;
    }
}

export default Content;
