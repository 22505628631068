import Content, { ContentInterface } from '@models/Content';

export interface WorkshopInterface extends ContentInterface {
    image: {
        childImageSharp: {
            fluid: { aspectRatio: number; base64: string; sizes: string; src: string; srcSet: string };
        };
    };
    tags: string[];
    learn: string[];
    prerequisites: string[];
}
class Workshop extends Content {
    public image: {
        childImageSharp: {
            fluid: { aspectRatio: number; base64: string; sizes: string; src: string; srcSet: string };
        };
    };
    public tags!: string[];
    public learn!: string[];
    public prerequisites!: string[];

    public constructor({
        index,
        image,
        date,
        length,
        title,
        description,
        body,
        path,
        tags,
        learn,
        prerequisites,
        parentId,
    }: WorkshopInterface) {
        super({ index, image, date, length, title, description, body, path, tags, learn, prerequisites, parentId });

        this.image = image;
        this.learn = learn;
        this.prerequisites = prerequisites;
    }
}

export default Workshop;
