import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

interface MetadataProps {
    pathname: string;
    ressource?: any;
}

export const Metadata: React.FC<MetadataProps> = ({ pathname, ressource }) => {
    const {
        site: {
            siteMetadata: { siteUrl, title },
        },
    } = useStaticQuery(graphql`
        query SiteMetadata {
            site {
                siteMetadata {
                    siteUrl
                    title
                }
            }
        }
    `);

    const defaultTitle = ressource ? `${title} | ${ressource.title}` : `${title}`;

    return (
        <Helmet defer={false} defaultTitle={defaultTitle}>
            <html lang="en" />
            <link rel="canonical" href={`${siteUrl}${pathname}`} />
            <meta name="docsearch:version" content="2.0" />
            <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover" />
            <meta property="og:url" content={siteUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en" />
            <meta property="og:site_name" content={title} />
            <meta property="og:image:width" content="512" />
            <meta property="og:image:height" content="512" />
        </Helmet>
    );
};
